import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import {
  FileIcon,
  DeleteIcon,
  DownloadIcon,
  FilePdfIcon,
  FileWordIcon,
  FileJpgIcon,
  FileGifIcon,
  FilePngIcon,
} from '../Icons';
import { FileProps } from './File.types';
import { TinyLoader } from '../Loader/Loader';
import { Spacer } from '../Spacer/Spacer';
import { Mime } from '../../../generated/types';
import { media } from '../../../styles/media';

export const File = ({
  filename,
  status = 'uploaded',
  isNew,
  mimeType = 'application/file',
  formattedMimetype,
  uuid,
  onDeleteClick,
  onDownloadClick,
}: React.PropsWithChildren<FileProps>) => {
  let icon = <></>;
  switch (formattedMimetype) {
    case Mime.Png:
      icon = <FilePngIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Gif:
      icon = <FileGifIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Jpeg:
      icon = <FileJpgIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Pdf:
      icon = <FilePdfIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
    case Mime.Doc:
      icon = <FileWordIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
    default:
      icon = <FileIcon size={1.5} color={isNew ? Colors.Contrast : Colors.Primary} />;
      break;
  }

  return (
    <Container $isNew={isNew}>
      {/* <pre>{JSON.stringify({ filename, status, isNew, mimeType, formattedMimetype, uuid }, null, 2)}</pre> */}
      <FileName>
        {icon}
        <Spacer x={8} />
        <span>{filename}</span>{' '}
        {status === 'uploading' && (
          <>
            <TinyLoader />
          </>
        )}{' '}
        {status === 'error' && <>Error</>}
      </FileName>
      <FileActions>
        {status === 'uploaded' && Boolean(onDownloadClick) && (
          <DownloadIcon size={1.5} onClick={onDownloadClick} color={isNew ? Colors.Contrast : Colors.Primary} />
        )}
        {Boolean(onDeleteClick) && (
          <>
            <Spacer x={8} />
            <DeleteIcon size={1.5} onClick={onDeleteClick} color={isNew ? Colors.Contrast : Colors.Primary} />
          </>
        )}
      </FileActions>
    </Container>
  );
};

const Container = styled.div<any>`
  border: 1px solid ${Colors.Primary};
  color: ${Colors.Primary};
  padding: 10px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;

  ${({ $isNew }) => {
    if ($isNew) {
      return css`
        border: 1px solid ${Colors.Contrast};
        color: ${Colors.Contrast};
      `;
    }
  }};
`;

const FileName = styled.div`
  display: flex;

  span {
    padding: 0 20px 0 0;
    line-height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;

    ${media.from_laptop`
      max-width: 250px;
    `}
  }
`;

const FileActions = styled.div`
  display: flex;
  flex: 70px 0 0;
  justify-content: flex-end;
  cursor: pointer;
`;
